<script setup lang="ts">
import { ref } from "vue";
// import { fetchHomeProductQuery } from '~/composables/useHomeProduct';

// Ref'leri bir obje içinde topluyoruz

const { data, pending, error } = await useAsyncData(
  "homeProducts",
  fetchHomeProductQuery
);

const homeResponse = ref<any>(data.value);
// console.log("homeResponse", homeResponse.value);

// Head bilgilerini güncelliyoruz
useHead(() => ({
  title: "Uygunsecim.com - Alışverişin için uygun seçimi bul!",
  meta: [
    {
      name: "description",
      content:
        "Uygunsecim.com alışverişin için en uygun fiyatı bulmana yardımcı olur.",
    },
    // Diğer meta etiketleri buraya eklenebilir
  ],
}));
</script>

<template>
  <!-- <pre>a = {{ homeResponse }}</pre> -->

  <div v-if="pending">Loading...</div>
  <div v-if="error">Error: {{ error.message }}</div>
  <main v-if="homeResponse">
    <!-- Highlights -->
    <div class="highlights mt40">
      <div class="container text-center">
        <div class="row align-items-start">
          <GeneralSwiperComponent
            :mcolumn="3"
            :items="[
              {
                title: 'Kendimi Şanslı Hissediyorum',
                url: '/kendimi-sansli-hissediyorum',
                bgclass: 'slider-bg-2',
                svg: '1',
              },
              {
                title: 'Son Yakalanan İndirimler',
                url: '/son-yakalanan-indirimler',
                bgclass: 'slider-bg-2',
                svg: '2',
              },
              // { title: 'Şansını Dene', url: '', bgclass: 'slider-bg-6' },
              {
                title: 'Fiyatı <br>Artanlar',
                url: '/fiyati-artanlar',
                bgclass: 'slider-bg-2',
                svg: '3',
              },
              {
                title: 'Son 1 Haftada <br>Fiyatı Düşenler',
                url: '/son-1-haftada-fiyati-dusunler',
                bgclass: 'slider-bg-2',
                svg: '4',
              },
              {
                title: 'Son 1 Ayda <br>Fiyatı Düşenler',
                url: '/son-1-ayda-fiyati-dusunler',
                bgclass: 'slider-bg-2',
                svg: '8',
              },
              // {
              //   title: '6 Aylık <br> Fiyat Takibi',
              //   url: '/son-6-ayda-fiyat-degisimi',
              //   bgclass: 'slider-bg-2',
              // },
              {
                title: 'Trend <br>Ürünler',
                url: '/trend-urunler',
                bgclass: 'slider-bg-2',
                svg: '6',
              },
            ]"
          >
            <template #default="{ item }">
              <MarketingHomeSliderItem :item="item" />
            </template>
          </GeneralSwiperComponent>
        </div>
      </div>
    </div>

    <!-- Öne Çıkanlar - Son Yakalanan İndirimler -->
    <div class="product-list">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h6 class="h-title">Son Yakalanan İndirimler</h6>
          </div>
          <div class="col-lg-12">
            <GeneralSwiperComponent
              :mcolumn="2"
              :items="homeResponse && homeResponse.latestDiscountsProducts"
            >
              <template #default="{ item }">
                <ProductItem :item="item" :reportid="0" />
              </template>
            </GeneralSwiperComponent>
          </div>
        </div>
      </div>
    </div>

    <!-- Haftanın Fırsatları -->
    <div class="product-list">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h6 class="h-title">Haftanın Fırsatları</h6>
          </div>
          <div class="col-lg-12">
            <GeneralSwiperComponent
              :mcolumn="2"
              :items="homeResponse && homeResponse.weekDiscountsProducts"
            >
              <template #default="{ item }">
                <ProductItem :item="item" :reportid="1" />
              </template>
            </GeneralSwiperComponent>
          </div>
        </div>
      </div>
    </div>

    <!-- Fiyatı Artanlar -->
    <div class="product-list">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h6 class="h-title">Fiyatı Artanlar</h6>
          </div>
          <GeneralSwiperComponent
            :mcolumn="2"
            :items="homeResponse && homeResponse.latestIncreasingPriceProducts"
          >
            <template #default="{ item }">
              <ProductItem :item="item" :reportid="0" />
            </template>
          </GeneralSwiperComponent>
        </div>
      </div>
    </div>

    <!-- Cep Telefonları -->
    <div class="product-list">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h6 class="h-title">Cep Telefonları</h6>
          </div>
          <div class="col-lg-12">
            <GeneralSwiperComponent
              :mcolumn="2"
              :items="homeResponse && homeResponse.cat17Products"
            >
              <template #default="{ item }">
                <ProductItem :item="item" :reportid="1" />
              </template>
            </GeneralSwiperComponent>
          </div>
        </div>
      </div>
    </div>

    <!-- Kişisel Bakım Ürünleri -->
    <div class="product-list">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h6 class="h-title">Kişisel Bakım Ürünleri</h6>
          </div>
          <div class="col-lg-12">
            <GeneralSwiperComponent
              :mcolumn="2"
              :items="homeResponse && homeResponse.cat354Products"
            >
              <template #default="{ item }">
                <ProductItem :item="item" :reportid="1" />
              </template>
            </GeneralSwiperComponent>
          </div>
        </div>
      </div>
    </div>

    <!-- Elektrikli Ev Aletleri -->
    <div class="product-list">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h6 class="h-title">Elektrikli Ev Aletleri</h6>
          </div>
          <div class="col-lg-12">
            <GeneralSwiperComponent
              :mcolumn="2"
              :items="homeResponse && homeResponse.cat371Products"
            >
              <template #default="{ item }">
                <ProductItem :item="item" :reportid="1" />
              </template>
            </GeneralSwiperComponent>
          </div>
        </div>
      </div>
    </div>

    <!-- Bilgisayarlar -->
    <div class="product-list">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h6 class="h-title">Bilgisayarlar</h6>
          </div>
          <div class="col-lg-12">
            <GeneralSwiperComponent
              :mcolumn="2"
              :items="homeResponse && homeResponse.cat24Products"
            >
              <template #default="{ item }">
                <ProductItem :item="item" :reportid="1" />
              </template>
            </GeneralSwiperComponent>
          </div>
        </div>
      </div>
    </div>

    <!-- Öne Çıkan Kategoriler -->
    <!-- <div class="categories-infos">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h6 class="h-title">Öne Çıkan Kategoriler</h6>
          </div>

          <div class="items">
            <HomeCategoriesList v-for="i of 8" :key="i" />
          </div>
        </div>
      </div>
    </div> -->
  </main>
</template>
